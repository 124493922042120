<template>
  <div class="content-filtes">
    <div class="content-filter-general-search">
      <v-text-field v-model="sSearch" label="Buscador de folio o placa del vehículo..."
        placeholder="Buscador general..." class="global-text-field" color="var(--primary-color-border-input)"
        background-color="var(--primary-color-menu)" persistent-placeholder outlined height="50px" clearable>
        <template v-slot:append>
          <v-icon color="#7C8088" size="17px"> mdi-magnify </v-icon>
        </template>
      </v-text-field>
    </div>
    <div class="content-filter-status">
      <v-select v-model="sStatus" :items="aItemStatusOrderIDPurchaseGlobal" item-text="text" item-value="value"
        class="global-select" color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
        outlined clearable>
        <template slot="label">
          <span>Estado <span class="important-data"></span></span>
        </template>
      </v-select>
    </div>
    <div class="content-filter-date-range" >
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false"  :return-value.sync="dates" transition="scale-transition" offset-y
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dates" outlined chips clearable class="global-text-field global-text-field-date"
            label="Seleccionar rango de fechas" placeholder="Seleccionar rango de fechas..."
            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)" v-bind="attrs"
            v-on="on" readonly>
            <template slot="append">
              <v-icon @click="menu = true">mdi-calendar-blank</v-icon>
            </template>
          </v-text-field>
          <!-- <v-combobox v-model="dates" multiple chips small-chips outlined clearable
            class="global-text-field global-text-field-date" label="Seleccionar rango de fechas"
            placeholder="Seleccionar rango de fechas..." color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)" v-bind="attrs" v-on="on">
            <template slot="append">
              <v-icon>mdi-calendar-blank</v-icon>
            </template>
          </v-combobox> -->
        </template>
        <v-date-picker v-model="dates" :max="sMaxDate" range no-title locale="es">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancelar
          </v-btn>
          <v-btn :disabled="!bAddDates" text color="primary" @click="$refs.menu.save(), save()">
            <span :class="bAddDates ? 'color-yellow-global' : ''">Guardar</span>
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sStatus: null,
      sSearch: "",
      dates: [],
      bAddDates: false,
      menu: false,
      sMaxDate: new Date().toISOString().slice(0, 10),
    };
  },
  beforeMount() {
  },
  methods: {
    save() {
      this.$refs.menu.save(this.dates);
      if (this.dates.length > 1) {
        let fecha_inicial = this.dates[0];
        let fecha_final = this.dates[1];
        this.$emit("setDate", {
          tStart: fecha_inicial,
          tEnd: fecha_final,
        });
      }
    },
  },
  computed: {
    aItemStatusOrderIDPurchaseGlobal() {
      return this.$store.state.aItemStatusOrderIDPurchaseGlobal;
    },
    dateRangeText() {
      if (this.dates !== null) {
        return this.dates.join(' ~ ')

      } else {
        return []
      }
    },
  },
  watch: {
    sSearch() {
      this.$emit("setSearch", this.sSearch);
    },
    sStatus() {
      this.$emit("setStatus", this.sStatus);
    },
    dates() {
      if (this.dates !== null) {
        if (this.dates.length > 1) {
          this.bAddDates = true;
          if (this.dates[0] > this.dates[1]) {
            let fecha_inicial = this.dates[1];
            this.dates = [];
            this.dates.push(fecha_inicial);
          }
        } else {
          this.bAddDates = false;
        }
      } else {
        this.bAddDates = false;
        this.$emit("setDate", {
          tStart: "",
          tEnd: "",
        });
      }
    },
  },
};
</script>

<style scoped>
.content-filtes {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.content-filter-general-search {
  width: 250px;
}

.content-filter-status {
  width: 300px;
  margin-left: 15px;
}

.content-filter-date-range {
  width: 320px;
  margin-left: 15px;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-filtes {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .content-filter-general-search {
    width: 100%;
    margin-bottom: 10px;
  }

  .content-filter-status {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .content-filter-date-range {
    width: 100%;
    margin-left: 0px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .content-filtes {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .content-filter-general-search {
    width: 100%;
    margin-bottom: 10px;
  }

  .content-filter-status {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .content-filter-date-range {
    width: 100%;
    margin-left: 0px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>